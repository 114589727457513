import * as React from "react";
import { useEffect } from "react";
import { withPrefix } from "gatsby";
import { useApplicationContext } from "../../provider";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { Helmet } from "react-helmet";
import Footer from "../components/footer-1";
import Header from "../components/header";
import { useLocation } from "@reach/router";
import "../components/css/remix-prompt.css";

const policyStyle = {
  width: '100%',
  float: 'left',
  fontFamily: '"Roboto", sans-serif',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '22px',
  paddingBottom: '16px',
}

const errorText = {
  color: 'red',
  fontFamily: '"Roboto", sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  paddingTop: 0,
  marginTop: 8,
}

export default function RemixPrompt() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const prompt = query.get('prompt');

  useEffect(() => {
    setState({ ...state, detailedDescription: prompt ?? applicationState.detailedDescription });
  }, []);

  const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");

  const [state, setState] = React.useState({
    detailedDescription: "",
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.name === 'detailedDescription') setShowTextareaError(false);
  };

  const { applicationState, setApplicationState } = useApplicationContext();

  const [showTextareaError, setShowTextareaError] = React.useState(false);
  const onGenerateClick = (e) => {
    e.preventDefault();

    if (!state.detailedDescription) {
      setShowTextareaError(true);
      return;
    }

    setApplicationState({
      ...applicationState,
      detailedDescription: state.detailedDescription,
    });
    if (isLoggedIn()) {
      if(currentUser.credits < 1) {
        navigate("pricing-options")
        return;
      }
      navigate("/choose-art");
      return;
    } else {
      navigate("/signin");
      return;
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <link href={withPrefix("assets/css/custom-styles.css")} rel="stylesheet" />
        </Helmet>
        <div className="container-main" id="page">
          <Header></Header>

          <main className="content-main">
            <div className="remix-prompt">
              <div className="container">
                <h1>Remix Prompt</h1>
                <div className="prompt-form">
                  <div className="box">
                    <div className="search-form">
                      <form method="get" action="#">
                        <label>Enter a detailed description:</label>
                        <div className="textarea-box" style={{ float: 'none' }}>
                          <textarea name="detailedDescription" id="commentsart2" cols="5" rows="5" value={state.detailedDescription} onChange={onChange} className="custom-scrollbar"
                            placeholder="a colorful abstract textile pattern featuring glitter and sparkles and unicorns, seamless, 8K, intricate, contest winner"></textarea>
                        </div>
                        {showTextareaError && <p style={errorText}>Oops! You forgot to enter a prompt.</p>}
                      </form>
                    </div>
                    <p style={policyStyle}>By clicking “Generate Art” I’m agreeing to the site-wide <a href="/content-policy" target="_blank"> content policy.</a></p>
                    <p style={policyStyle}>Images generated by stable diffusion. <a href="https://stability.ai" target="_blank">Learn more.</a></p>
                    <div className="bottom-btn">
                      <div className="btn-out"><a href="#" onClick={onGenerateClick} className="btn">REGENERATE ART</a></div>
                      <div className="image-count"><a href="#">.20 Credits / Image</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}
